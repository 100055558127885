import { render, staticRenderFns } from "./MyReferralCode.vue?vue&type=template&id=665d2965&scoped=true"
import script from "./js/my-referral-code.js?vue&type=script&lang=js&external"
export * from "./js/my-referral-code.js?vue&type=script&lang=js&external"
import style0 from "./MyReferralCode.vue?vue&type=style&index=0&id=665d2965&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665d2965",
  null
  
)

export default component.exports