import { mapGetters, mapActions } from 'vuex'
import { showVueToast } from '@/utils'
// const ActionPrakerjaModal = () => import(/* webpackChunkName: "action-prakerja-modal" */ '@/components/ActionPrakerjaModal')
export default {
  name: 'my-referral-code',
  components: {
    // ActionPrakerjaModal
  },
  computed: {
    ...mapGetters('common', ['isMobile'])
  },
  data: () => ({
    codeReferral: '',
    linkWhatsApp: '',
    linkTelegram: '',
    referralPhone: '',
    totalBalance: '',
    isModalVisible: false,
    listHistoryReferral: [],
    titleModal: 'Ubah Nomor Telepon',
    descriptionModal: 'Masukkan nomor telepon baru yang terdaftar di akun DANA',
    typeModal: 'changePhone'
  }),
  mounted() {
    this.getReferralUser()
    this.getHistoryReferral()
  },
  methods: {
    ...mapActions('prakerja', ['goMyReferralFromHome', 'getMyReferral', 'getMyReferralList']),
    getReferralUser() {
      this.goMyReferralFromHome({ access: false })
      this.getMyReferral()
        .then((response) => {
          this.referralPhone = response.referral_wallet_id
          this.totalBalance = this.formatRupiah(response.total_sudah_dibayar)
          this.codeReferral = 'https://g2academy.co/prakerja/' + response.id
          this.linkWhatsApp = `https://api.whatsapp.com/send?text=Yuk%20daftar%20Kelas%20Prakerja%20di%0AG2Academy!%0A%0ACukup%20klik%20link%20di%20bawah%20untuk%20%0Amelihat%20kelas%20dan%20memulai%0Ahttps%3A%2F%2Fwww.g2academy.co%2Fprakerja%2F${response.id}`
          this.linkTelegram = `https://t.me/share/url?url=https://www.g2academy.co/prakerja/${response.id}&text=Yuk%20daftar%20Kelas%20Prakerja%20di%20%0AG2Academy!%0A%0ACukup%20klik%20link%20di%20atas%20untuk%20%0Amelihat%20kelas%20dan%20memulai`
        })
        .catch((response) => {})
    },
    setReferralPhone(value) {
      this.referralPhone = value
    },
    copyCode() {
      const codeToCopy = document.querySelector('#referral')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      document.execCommand('copy')
      showVueToast('Code referral berhasil disalin!', 'success', 3000)
    },

    getHistoryReferral() {
      this.getMyReferralList()
        .then((res) => {
          this.listHistoryReferral = res
        })
        .catch((res) => res)
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible
    },
    formatRupiah(totalBalance) {
      let numberToString = ''
      let sisa = 0
      let ribuan = 0
      let separator = ''
      let rupiah = 0
      numberToString = totalBalance.toString()
      sisa = numberToString.length % 3
      rupiah = numberToString.substr(0, sisa)
      ribuan = numberToString.substr(sisa).match(/\d{3}/g)

      if (ribuan) {
        separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }

      return rupiah
    }
  }
}
